<template>
  <div class="search-container">
    <van-search
      v-model="searchValue"
      :placeholder="placeholder"
      :left-icon="require('@/assets/images/icon/search.png')"
      @search="onSearch"
    >
    </van-search>
    <div
      class="search-button"
      @click="onSearch"
    >搜索</div>
  </div>
</template>

<script>

export default {
  components: {

  },
  props: {
    value: {
      type: [String, Number],
      default: '',
    },
    placeholder: {
      type: String,
      default: '搜索产品',
    },
  },
  data() {
    return {

    }
  },
  computed: {
    searchValue: {
      set(val) {
        this.$emit('input', val);
      },
      get() {
        return this.value;
      }
    }
  },
  methods: {
    onSearch() {
      this.$emit('search', this.searchValue);
    }
  }
};
</script>
<style lang="scss" scoped>
.search-container {
  width: 100%;
  height: 68px;
  border-radius: 37px;
  border: 1px solid #006afa;
  box-sizing: border-box;
  overflow: hidden;
  display: flex;
  align-items: center;
  background-color: #ffffff;
  :deep(.van-search) {
    padding: 0;
    flex: 1;
  }
  :deep(.van-search__content) {
    background-color: #ffffff;
    padding-left: 24px;
  }
  :deep(.van-cell) {
    padding: 0;
  }
  :deep(.van-field__left-icon) {
    display: flex;
    align-items: center;
    margin-right: 18px;
    .van-icon__image {
      width: 28px;
      height: 28px;
    }
  }
  :deep(.van-field__body) {
    font-size: 28px;
    font-weight: 400;
  }
  .search-button {
    width: 130px;
    height: calc(100% - 10px);
    background: linear-gradient(146deg, #74c5ff 0%, #187dfb 100%);
    border-radius: 37px 37px 37px 37px;
    // border: 1px solid #ffffff;
    display: flex;
    justify-content: center;
    align-items: center;

    font-size: 26px;
    font-weight: 500;
    color: #e5f1ff;

    margin: 5px;
  }
}
</style>