<template>
  <div class="toy-container">
    <van-list
      v-model="listLoading"
      :finished="finished"
      finished-text="没有更多了"
      :immediate-check="immediateCheck"
      @load="onLoad"
    >
      <van-checkbox-group v-model="checkedList">
        <div class="toy-list-container">
          <div
            class="toy-item"
            v-for="(item, i) in list"
            :key="i"
          >
            <van-checkbox
              v-if="openCheckbox"
              class="checkbox"
              :name="item[checkedDataKey]"
              :icon-size="$px2vw(32)"
              :disabled="disabledList.indexOf(item[checkedDataKey]) !== -1"
            ></van-checkbox>
            <div
              class="item-img"
              @click="$router.push('/toy/detail/'+item[toyIdKey])"
            >
              <img :src="ListPic(item.picture)" />
            </div>
            <div
              class="item-content"
              @click="$router.push('/toy/detail/'+item[toyIdKey])"
            >
              <div class="item-name">{{item.name}}</div>
              <div class="item-number">
                <span>{{item.article_number}}</span>
              </div>
              <div class="item-bottom">
                <div class="price">
                  <span class="price-unit">￥</span>
                  <span class="num">{{item.price}}</span>
                  <span class="num-unit">/{{item.quote_unit}}</span>
                </div>
                <div class="quoted-price">{{item.supplierCount}}个报价</div>
              </div>
            </div>
          </div>
        </div>
      </van-checkbox-group>
    </van-list>
  </div>
</template>

<script>
import { arrayAminusB, ListPic } from "@/lib/utils.js";
export default {
  components: {

  },
  props: {
    list: {
      type: Array,
      default: function () {
        return [];
      },
    },
    loading: {
      type: Boolean,
      default: false,
    },
    finished: {
      type: Boolean,
      default: false,
    },
    error: {
      type: Boolean,
      default: false,
    },
    immediateCheck: {
      type: Boolean,
      default: true,
    },
    //打开复选框
    openCheckbox: {
      type: Boolean,
      default: false,
    },
    //禁用的复选框列表
    disabledList: {
      type: Array,
      default: () => {
        return [];
      }
    },
    //多选款选中的字段
    checkedDataKey: {
      type: String,
      default: 'id',
    },
    //产品id的字段
    toyIdKey: {
      type: String,
      default: 'id',
    }
  },
  data() {
    return {
      //选中的数据
      checkedList: [],
    }
  },
  methods: {
    ListPic,
    onLoad() {
      this.$emit('load');
    },
    //获取选中的id列表
    getCheckedList() {
      return this.checkedList;
    },
    setAll(val) {
      if (val) {
        this.checkedList = this.validList.map(item => item[this.checkedDataKey]);
      } else {
        this.checkedList = [];
      }
    }
  },
  computed: {
    allChecked: {
      set(val) {
        this.setAll(val);
      },
      get() {
        return this.checkedList.length > 0 && this.checkedList.length == this.validList.length;
      }
    },
    listLoading: {
      set(val) {
        this.$emit('update:loading', val);
      },
      get() {
        return this.loading;
      }
    },
    validList() {
      return this.list.filter((item) => this.disabledList.indexOf(item[this.checkedDataKey]) === -1);
    }
  },
  watch: {
    openCheckbox:{
      immediate: true,
      handler(val){
        if(!val) this.setAll(false);
      }
    }
  }
};
</script>
<style lang="scss" scoped>
.toy-container {
  :deep(.toy-list-container) {
    display: flex;
    justify-content: space-between;
    flex-flow: wrap;
    .toy-item {
      display: flex;
      flex-direction: column;
      position: relative;
      width: 345px;
      box-sizing: border-box;
      min-height: 380px;
      background: #ffffff;
      border-radius: 10px;
      margin-bottom: 20px;
      .checkbox {
        position: absolute;
        left: 20px;
        top: 20px;
      }
      .item-img {
        margin: 20px 48px;
        height: 188px;
        img {
          width: 100%;
          height: 100%;
          object-fit: contain;
        }
      }
      .item-content {
        padding-left: 30px;
        padding-right: 20px;
        padding-bottom: 20px;
        .item-name {
          font-size: 30px;
          font-weight: bold;
          color: #4f4f4f;
          @include not-wrap;
        }
        .item-number {
          margin-top: 7px;
          display: flex;
          span {
            font-size: 24px;
            font-weight: 400;
            color: #ff9700;
            border-radius: 4px;
            border: 1px solid #ff9700;
            padding: 0 14px;

            max-width: 88%;
            display: inline-block;
            @include not-wrap;
            vertical-align:top;
          }
        }
        .item-bottom {
          margin-top: 20px;
          display: flex;
          justify-content: space-between;
          align-items: baseline;
          .price {
            display: flex;
            align-items: baseline;
            .price-unit {
              font-size: 18px;
              color: #f33636;
              font-weight: 400;
            }
            .num {
              font-size: 28px;
              color: #f33636;
              font-weight: bold;
            }
            .num-unit {
              font-size: 18px;
              font-weight: 400;
              color: #999999;
            }
          }
          .quoted-price {
            display: flex;
            align-items: flex-end;
            line-height: 22px;
            font-size: 22px;
            font-weight: 400;
            color: #ff8282;
          }
        }
      }
    }
  }
}
</style>