<template>
  <div class="container">
    <navbar title="搜索产品"></navbar>
    <c-sticky :offset-top="$px2vwMin(92)">
      <div class="top-container">
        <div class="search-main">
          <search-input
            @search="search"
            v-model="keyword"
          ></search-input>
        </div>
      </div>
    </c-sticky>
    <div class="toy-list">
      <toy-list
        @load="onLoad"
        :loading="loading"
        :finished="finished"
        :list="list"
        :immediateCheck="false"
      ></toy-list>
    </div>
  </div>
</template>

<script>
import CSticky from "@/components/c-sticky.vue";
import Navbar from "@/components/navbar.vue";
import SearchInput from "@/components/search-input.vue";
import ToyList from "@/components/toy-list.vue";
import { toyList } from "@/api/toy.js";
export default {
  name: "searchToy",
  components: {
    CSticky,
    Navbar,
    SearchInput,
    ToyList,
  },
  data() {
    return {
      loading: false,
      finished: false,
      list: [],
      keyword: '',
      page: 0,
    };
  },
  mounted() {
    if (this.$route?.params?.keyword) {
      this.keyword = this.$route?.params?.keyword;
    }
    this.search();
  },
  methods: {
    search() {
      this.page = 0;
      this.list = [];
      this.finished = false;
      this.onLoad();
    },
    onLoad() {
      this.loading = true;
      this.page++;
      let data = { page: this.page, name: this.keyword };
      toyList(data).then(res => {
        if (res.code == 0) {
          if (res.data.length == 0) {
            this.finished = true;
          }

          if (this.page == 1) {
            this.list = res.data;
          } else {
            this.list = [...this.list, ...res.data];
          }
          this.loading = false;
        } else {
          this.$toast(res.msg);
        }
      })
    },
  },
};
</script>
<style lang="scss" scoped>
.container {
  .top-container {
    padding: 0 20px;
    height: 110px;
    background: #ffffff;
    display: flex;
    flex-direction: column;
    margin-bottom: 20px;
    .search-main {
      margin-top: 20px;
      margin-bottom: 20px;
    }
  }
  .toy-list {
    padding: 0 20px;
    margin-top: 20px;
    box-sizing: border-box;
    @include safe-area-inset-bottom;
  }
}
</style>
