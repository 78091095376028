import axios from '@/lib/axios'

//产品列表
export const toyList = (data) => {
  return axios.request({
    url: 'api/toyList',
    data: data,
    method: 'post'
  });
};

//产品详情
export const toyDetail = (data) => {
  return axios.request({
    url: 'api/toyDetail',
    data: data,
    method: 'post'
  });
};

//产品收藏
export const toyCollect = (data) => {
  return axios.request({
    url: 'api/toyCollect',
    data: data,
    method: 'post'
  });
};

//产品取消收藏
export const toyCollectDelete = (data) => {
  return axios.request({
    url: 'api/toyCollectDelete',
    data: data,
    method: 'post'
  });
};

//产品收藏列表
export const toyCollectList = (data) => {
  return axios.request({
    url: 'api/toyCollectList',
    data: data,
    method: 'post'
  });
};

//浏览列表
export const toyBrowseList = (data) => {
  return axios.request({
    url: 'api/toyBrowseList',
    data: data,
    method: 'post'
  });
}

//删除浏览记录
export const toyBrowseDelete = (data) => {
  return axios.request({
    url: 'api/toyBrowseDelete',
    data: data,
    method: 'post'
  });
}

//下单记录
export const toyOrderTmp = (data) => {
  return axios.request({
    url: 'api/toyOrderTmp',
    data: data,
    method: 'get'
  });
}